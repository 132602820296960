<template>
  <div>
    <q-form ref="editForm">
      <c-card title="비상조치 시나리오 기본정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn 
              v-show="editable" 
              label="시나리오 불러오기"
              icon="save_alt" 
              @btnClicked="loadScenario" />
            <!-- 저장 -->
            <c-btn
              v-if="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="emerge"
              :mappingType="saveType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveInfoCallback" 
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-dept
              :required="true" 
              type="edit"
              label="관리부서"
              name="deptCd"
              v-model="emerge.deptCd">
            </c-dept>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!-- 계획서명 -->
            <c-text
              :required="true" 
              :editable="editable"
              label="시나리오명"
              name="trainingScenarioName"
              v-model="emerge.trainingScenarioName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-plant
              :required="true" 
              :editable="editable" 
              type="edit" 
              name="plantCd"
              v-model="emerge.plantCd"/>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 사용여부 -->
            <c-radio
              :editable="editable"
              :comboItems="useFlagItems"
              label="LBLUSEFLAG"
              name="useFlag"
              v-model="emerge.useFlag">
            </c-radio>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      ref="scenarioTable"
      title="시나리오 항목"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :isExcelDown="false"
      :filtering="false"
      :gridHeight="gridHeight"
      selection="multiple"
      rowKey="generationLocationId"
      :columns="grid.columns"
      :data="emerge.scenarios"
      :merge="grid.merge"
      @innerBtnClicked="innerBtnClicked"
      @table-data-change="datachange"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="추가" 
            icon="add" 
            @btnClicked="addRowScenario" />
          <c-btn 
            v-if="editable && emerge.scenarios.length > 0" 
            label="제외" 
            icon="remove" 
            :showLoading="false"
            @btnClicked="removeItem" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'generationLocationName'">
          <c-text-column
            :editable="editable"
            :col="col"
            :props="props"
            v-model="props.row['generationLocationName']"
            @datachange="datachange(props)"
          />
          <div v-if="editable">
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                icon="add"
                color="red-6"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props)">
                <q-tooltip>
                  발생장소별 훈련내용 추가
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </div>
        </template>
        <template v-if="col.name==='situationDevelopmentName'">
          <c-text-column
            :editable="editable"
            :col="col"
            :props="props"
            v-model="props.row['situationDevelopmentName']"
            @datachange="datachange(props)"
          />
          <div v-if="editable">
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                icon="add"
                color="light-blue"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props)">
                <q-tooltip>
                  상황전개별 발생장소 추가
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </div>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'emergency-scenario',
  props: {
    emerge: {
      type: Object,
      default: () => ({
        trainingScenarioId: '',
        deptCd: '',
        trainingScenarioName: '',
        emergencyPlanName: '',
        plantCd: '',
        useFlag: '',
        scenarios: [],
        deleteScenarios: [],
        evalItems: [],
        deleteEvalItems: [],
        regUserId: '',
        chgUserId: '',
      }),
    },
    contentHeight: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      grid: {
        merge: [
          // { index: 0, colName: 'situationDevelopmentName' },
          // { index: 1, colName: 'generationLocationId' },
        ],
        columns: [
          
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      saveData: [],
      isSave: false,
      editable: true,
      listUrl: '',
      saveUrl: '',
      saveType: 'POST',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let height = this.contentHeight - 240;
      if (height < 450) {
        height = 450;
      }
      return String(height) + 'px';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.eap.scenario.insert.url;
      // code setting
      // list setting
      this.setHeader();
    },
    setHeader() {
      this.grid.columns = [
        {
          required: true,
          name: 'situationDevelopmentName',
          field: 'situationDevelopmentName',
          label: '상황전개',
          align: 'center',
          type: 'custom',
          style: 'width:170px',
          sortable: false,
        },
        {
          required: true,
          name: 'generationLocationName',
          field: 'generationLocationName',
          label: '발생장소',
          align: 'center',
          type: 'custom',
          style: 'width:170px',
          sortable: false,
        },
        {
          required: true,
          name: 'trainingContents',
          field: 'trainingContents',
          label: '훈련내용',
          type: 'text',
          align: 'left',
          style: 'width:600px',
          sortable: true,
        },
        {
          required: true,
          name: 'trainingManager',
          field: 'trainingManager',
          label: '담당자',
          type: 'text',
          align: 'center',
          style: 'width:150px',
          sortable: true,
        },
        {
          name: 'trainingMaterial',
          field: 'trainingMaterial',
          label: '준비물',
          type: 'textarea',
          align: 'left',
          style: 'width:150px',
          sortable: true,
        },
        {
          required: true,
          name: 'sortOrder',
          field: 'sortOrder',
          label: '순번',
          align: 'center',
          sortable: true,
          style: 'width:60px',
          type: 'number',
        },
      ]
    },
    getDetail() {
      this.$emit("getDetail");
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    innerBtnClicked(col, props) {
      if (col.name === 'generationLocationName') {
        // 발생장소
        this.emerge.scenarios.splice(props.rowIndex, 0, {
          situationDevelopmentId: props.row.situationDevelopmentId,
          situationDevelopmentName: props.row.situationDevelopmentName,
          generationLocationId: props.row.generationLocationId,
          generationLocationName: props.row.generationLocationName,
          trainingContents: '',  // 훈련내용
          trainingManager: '',  // 담당자
          trainingMaterial: '',  // 준비물
          editFlag: 'C',
          regUserId: this.$store.getters.user.userId,
          sortOrder: 0,
        })
      } else if (col.name === 'situationDevelopmentName') {
        // 상황전개
        this.emerge.scenarios.splice(props.rowIndex, 0, {
          trainingScenarioId: props.row.trainingScenarioId,  // 시나리오 일련번호
          situationDevelopmentId: props.row.situationDevelopmentId,  // 상황전개 일련번호
          situationDevelopmentName: props.row.situationDevelopmentName,   // 상황전개
          generationLocationId: uid(),  // 발생장소 일련번호
          generationLocationName: '',  // 발생장소
          trainingContents: '',  // 훈련내용
          trainingManager: '',  // 담당자
          trainingMaterial: '',  // 준비물
          editFlag: 'C',
          regUserId: this.$store.getters.user.userId,
          sortOrder: 0,
        })
      }
    },
    saveInfo() {
      if (this.emerge.trainingScenarioId) {
        this.saveUrl = transactionConfig.sop.eap.scenario.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.eap.scenario.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.grid.columns, this.emerge.scenarios)) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGSAVE', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.emerge.regUserId = this.$store.getters.user.userId
                this.emerge.chgUserId = this.$store.getters.user.userId

                this.isSave = !this.isSave
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveInfoCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.emerge.trainingScenarioId) {
        this.emerge.trainingScenarioId = result.data
        this.$emit('changeStatus', result.data);
      }
      this.$emit("getDetail");
    },
    addRowScenario() {
      this.emerge.scenarios.push({
        trainingScenarioId: this.emerge.trainingScenarioId,  // 시나리오 일련번호
        situationDevelopmentId: uid(),  // 상황전개 일련번호
        situationDevelopmentName: '',   // 상황전개
        generationLocationId: uid(),  // 발생장소 일련번호
        generationLocationName: '',  // 발생장소
        trainingContents: '',  // 훈련내용
        trainingManager: '',  // 담당자
        trainingMaterial: '',  // 준비물
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId,
        sortOrder: 0,
      })
    },
    removeItem() {
      let selectData = this.$refs['scenarioTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.emerge.deleteScenarios) {
            this.emerge.deleteScenarios = []
          }
          if (this.$_.findIndex(this.emerge.deleteScenarios, { generationLocationId: item.generationLocationId }) === -1
            && item.editFlag !== 'C') {
              this.emerge.deleteScenarios.push(item)
          }
          this.emerge.scenarios = this.$_.reject(this.emerge.scenarios, item)
        })
      }
    },
    loadScenario() {
      this.popupOptions.title = '훈련 시나리오 검색';
      this.popupOptions.param = {
        type: 'single',
        trainingScenarioId: this.emerge.trainingScenarioId,
        deptCd: this.emerge.deptCd
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/eap/scenario/emergencyScenarioEvalPop'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTraningScenarioPopup;
    },
    /* eslint-disable no-unused-vars */ 
    closeTraningScenarioPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.emerge.scenarios = [];
        this.emerge.evalItems = [];
        this.$http.url = this.$format(selectConfig.sop.eap.emergTraining.get.url, data[0].trainingScenarioId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.emerge.trainingScenarioName = _result.data.trainingScenarioName
          if (_result.data.scenarios) {
            this.$_.forEach(_result.data.scenarios, _item =>{
              this.emerge.scenarios.push({
                trainingScenarioId: uid(),
                situationDevelopmentName: _item.situationDevelopmentName,
                generationLocationName: _item.generationLocationName,
                trainingContents: _item.trainingContents,
                trainingManager: _item.trainingManager,
                trainingMaterial: _item.trainingMaterial,
                sortOrder: _item.sortOrder,
                regUserId: this.$store.getters.user.userId,
                chgUserId: this.$store.getters.user.userId,
                editFlag: 'C',
              })
            })
          }
        },);
      }
    },
  }
};
</script>
